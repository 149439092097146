<template>
    <RouterView />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router';
import { setCookie } from '@/helpers/cookies.js';
import { useHead } from '@unhead/vue'

const route = useRoute();

useHead({ 
    script: [
        {
            src: '/js/metrika.js'
        },
        {
            src: 'https://app.uiscom.ru/static/cs.min.js?k=h9cLWzNYmXvCOz_pSLM72DW6ZGGuHG5W',
            async: true
        }
    ]},
    { mode: 'client' }
);

onMounted(()=> {
    if (Object.keys(route.query).length) {
        for (const key in route.query) {
            setCookie(key, route.query[key])
        }
    }
})
</script>

